module.exports = [{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"P65cINYT0PAAQjAYY7MYcPduJpzjlw4z","devKey":"P65cINYT0PAAQjAYY7MYcPduJpzjlw4z","trackPage":false},
    },{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-115884486-3"],"gtagConfig":{"optimize_id":"GTM-TVRGMZ2","cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"436630336926684","includeInDevelopment":false,"debug":false,"language":"en_US"},
    },{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2113839892253499"},
    },{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/SalazarPardo/Documents/W/Elenas/elenas-landing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
